import React from "react";
import styled from "styled-components";

const RowLabel = styled("label")`
  .row-label {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 .14285714em;
    padding: .5833em .833em;
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 700;
  }
`;

const RowItems = styled.div`
  display: flex !important;
  flex-direction: row;

  >* {
    margin: 0 .5rem !important;
  }
`;

export default (props: any) => {
  const { children, label, type, ...rest } = props;
  return (
    <div {...rest}>
      {label && <RowLabel>{label}</RowLabel>}
      <RowItems>
        {children}
      </RowItems>
    </div>);
};
