import { asField } from "informed";
import noop from "lodash.noop";
import React, { Fragment } from "react";
import { Input } from "semantic-ui-react";
import styled from "styled-components";

import colors from "../../../styles/colors";
import theme from "../../../styles/theme";
import Label from "./Label";
import Notification from "./Notification";
import Popup from "./Popup";

const StyledInput = styled(Input)`
  &&&& input {
    background: ${theme.background};
    color: ${theme.textPrimary};
    border-color: ${theme.borderColor};

    :focus {
      border-color: ${colors.primary};
    }

    ::placeholder {
      color: ${theme.textSecondary};
    }
  }
`;

const TextInput = asField(({ fieldState, fieldApi, icon, withErrorMessage, ...props }: any) => {
  const { value = "", error } = fieldState;
  const { setValue } = fieldApi;
  const { onChange = noop, onBlur = false, defaultValue = "", forwardedRef, ...rest } = props;

  return (
    <>
      <StyledInput
        icon={icon}
        iconPosition={icon && "left"}
        ref={forwardedRef}
        value={!value && value !== "" ? defaultValue : value}
        error={!!error}
        onChange={(e: any) => {
          if (onChange) {
            setValue(e.target.value);
            if (typeof onChange === "function") onChange(e);
          }
        }}
        onBlur={(e: any) => {
          if (onBlur) {
            setValue(e.target.value);
            if (typeof onBlur === "function") onBlur(e);
          }
        }}
        {...rest}
      />
      {withErrorMessage && <Notification>{error}</Notification>}
    </>);
});

const TextField = ({ id, label, parentNode, description, ...rest }: any) => (
  <Popup
    description={description}
    trigger={
      <div style={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
        {(label || parentNode === "row") && <Label htmlFor={id}>{label}</Label>}
        <TextInput
          {...rest}
          field={id}
          id={id}
        />
      </div>}
  />);

export default TextField;
