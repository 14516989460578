import styled from "styled-components";
import makecolor from "tinycolor2";

import colors from "../../styles/colors";

interface IClickableProps {
  primary?: boolean;
  color?: string;
}

const getColor = (primary?: boolean, color?: string) => {
  if (primary) return colors.primary;
  if (color) return colors[color];
  return "#cacbcd";
};

const Clickable = styled.span`
  cursor: pointer;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  font-family: Lato, sans-serif;
  font-size: 1.1rem;
  color: ${({ primary, color }: IClickableProps) =>
    makecolor(getColor(primary, color)).brighten(20).toString()};

  :hover {
    color: ${({ primary, color }: IClickableProps) => getColor(primary, color)};
  }
`;

export default Clickable;
