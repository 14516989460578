import noop from "lodash.noop";
import * as React from "react";
import { Icon, Image, Popup } from "semantic-ui-react";
import styled from "styled-components";
import color from "tinycolor2";

import colors from "../../styles/colors";
import theme from "../../styles/theme";

interface ISquareCardProps {
  src?: string;
  alt?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  icon?: any;
  readOnly?: boolean;
  onCardSelection?: () => any;
  onCardInfo?: () => any;
  onCardEdit?: () => any;
}

const Background = styled.div`
  display: flex;
  position: relative;
  background: white;
  flex-direction: column;
  color: ${theme.textPrimary};
  border: 1px solid ${colors.border};
  border-radius: 4px;
  min-width: 13rem;
  max-width: 16rem;
  height: 14rem;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  :hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${theme.textPrimary};
  margin-bottom: 4px;
  max-height: 2.8rem;
  overflow: hidden;
`;

const Info = styled.div`
  font-size: 1.2rem;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;

  :hover {
    color: ${theme.textPrimary}
  }
`;

const EditButton = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 1.7rem;
  background: ${colors.primary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
  height: 3rem;
  width: 3rem;

  .icon {
    align-self: center;
  }

  :hover {
    background: ${color(colors.primary).lighten(2).toString()};
  }
`;

const TextWrapper = styled.div`
  padding: 1rem;
  color: ${theme.textSecondary};
  height: 6.5rem;
`;

const ImageWrapper = styled.div`
  background: ${theme.background};
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-self: center;
  background: white;

  .ui.image {
    border-top: 1px solid ${theme.borderColor};
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const SquareCard = (props: ISquareCardProps) => {
  const { title, icon, subtitle, src, alt = "", readOnly = false, description } = props;
  const { onCardSelection = noop, onCardInfo = noop, onCardEdit = noop } = props;
  return (
    <Background
      onClick={(e) => {
        e.stopPropagation();
        onCardSelection();
      }}
    >
      <TextWrapper>
        <Title>{title}</Title>
        <div><Icon name={icon} />{subtitle}</div>
        {description && <Popup
          basic
          trigger={<Info
            onClick={(e) => {
              e.stopPropagation();
              onCardInfo();
            }}
          >
            <Icon name="info circle" />
          </Info>}
        >
          {description}
        </Popup>}
      </TextWrapper>
      <ImageWrapper>
        {src && <Image src={src} alt={alt} />}
        {!readOnly &&
          <EditButton
            onClick={(e) => {
              e.stopPropagation();
              onCardEdit();
            }}
          >
            <Icon name="pencil alternate" size="small" />
          </EditButton>}
      </ImageWrapper>
    </Background >);
};

export default SquareCard;
