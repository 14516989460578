import * as React from "react";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

import colors from "../../styles/colors";
import media from "../../styles/media";

const Background = styled.div`
  width: 100%;
  margin: 1rem 0 2rem 0;
  border-bottom: 1px solid ${colors.border};
`;

const HeaderContainer = styled(Container)`
  &&&& {
    display: flex;
    justify-content: space-between;

    .ui.button {
      margin-bottom: .6rem;
      height: 2.4rem;
    }

    .ui.search {
      margin-bottom: .6rem;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  > * {
    align-self: initial;
    margin-right: 3rem;
    line-height: 3rem;
  }

  ${media.mobile`
      display: none;
  `}
`;

const Item: any = styled.div`
  white-space: nowrap;
  min-width: 4rem;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  color: ${(props: any) => props.active ? colors.font : colors.font1};
  border-bottom: 2px solid ${(props: any) => props.active ? colors.primary : "transparent"};
`;

class SubHeader extends React.Component {

  static Menu = Menu;
  static Item = Item;

  render() {
    const { children, ...rest } = this.props;
    return (
      <Background {...rest}>
        <HeaderContainer>
          {children}
        </HeaderContainer>
      </Background>
    )
  }
}

export default SubHeader;
