import styled from "styled-components";

import theme from "../../../styles/theme";

const Label = styled.label`
  display: block;
  font-size: .8rem;
  height: 1.25rem;
  color: ${theme.textSecondary};
`;

export default Label;
