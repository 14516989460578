import noop from "lodash.noop";
import React from "react";
import { Icon } from "semantic-ui-react";

import ConnectionIndicator from "../../elements/ConnectionIndicator/ConnectionIndicator";
import { TileBackground, TileContent, TileIconBackground, TileMenu, TileMenuItem, TileTitle } from "./Tile.styles";

function Tile(props: any) {
  const {
    title,
    showConnection,
    isConnected = false,
    children,
    editable = false,
    onEdit = noop,
    onDelete = noop,
    selected,
    ...rest
  } = props;

  const content = React.Children.toArray(children).filter((child) =>
    (!child.type || child.type.displayName !== "DraggableCore"));
  const handler = React.Children.toArray(children).filter((child) =>
    (child.type && child.type.displayName === "DraggableCore"));

  return (
    <TileBackground selected={selected} editable={editable} {...rest}>
      {
        editable &&
        <TileMenu>
          <TileMenuItem onClick={onDelete}><Icon name="trash" /></TileMenuItem>
          <TileMenuItem onClick={onEdit}><Icon name="cog" /></TileMenuItem>
          <TileTitle>{title}</TileTitle>
        </TileMenu>
      }
      <TileContent>
        {content.length ? content : <TileIconBackground size="huge" name="th" />}
      </TileContent>
      {
        editable && showConnection &&
        <ConnectionIndicator connected={isConnected} style={{ marginTop: ".25rem" }} />
      }
      {editable && handler}
    </TileBackground>);
}

export default Tile;
