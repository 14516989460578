import { asField } from "informed";
import React, { Fragment } from "react";
import { TextArea } from "semantic-ui-react";
import styled from "styled-components";

import theme from "../../../styles/theme";
import Notification from "./Notification";

const StyledTextArea = styled(TextArea)`
  color: ${theme.textPrimary};
  background: ${theme.background};
  border: ${theme.background};
`;

const TextAreaField = asField(({ fieldState, fieldApi, fluid, ...props }: any) => {
  const { value, error } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, defaultValue, forwardedRef, ...rest } = props;

  return (
    <Fragment>
      <StyledTextArea
        {...rest}
        ref={forwardedRef}
        value={!value && value !== 0 ? defaultValue : value}
        error={error}
        onChange={(e: any, data: any) => {
          setValue(data.value);
          if (onChange) onChange(data.value);
        }}
        onBlur={(e: any) => {
          setTouched();
          if (onBlur) onBlur(e);
        }}
      />
      <Notification>{error}</Notification>
    </Fragment>);
});

export default TextAreaField;
