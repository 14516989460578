import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Ref } from "semantic-ui-react";

const makeDraggingStyle = (shouldMake: any, draggingStyle: any) => {
  return (shouldMake ? draggingStyle : {});
};

const SortableItem = ({ children, index, id, draggingStyle = {} }: any) => {
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {
        (provided: any, snapshot: any) => (
          <Ref innerRef={provided.innerRef}>
            {
              React.cloneElement(React.Children.toArray(children)[0], {
                ...provided.draggableProps,
                ...provided.dragHandleProps,
                style: {
                  ...provided.draggableProps.style,
                  ...React.Children.toArray(children)[0].props.style,
                  ...makeDraggingStyle(snapshot.isDragging, draggingStyle),
                },
              })
            }
          </Ref>)
      }
    </Draggable>);
};

const SortableList = (props: any) => {
  const { onDragEnd, children, direction = "vertical", id = "droppable" } = props;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id} direction={direction}>
        {
          ({ innerRef, placeholder }: any) =>
            <>
              <Ref innerRef={innerRef}>
                {children}
              </Ref>
              {placeholder}
            </>
        }
      </Droppable>
    </DragDropContext>);
};

SortableList.defaultProps = {
  onDragEnd: () => { },
};

SortableList.Item = SortableItem;

export default SortableList;
