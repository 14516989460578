import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import colors from "../../styles/colors";
import theme from "../../styles/theme";

export const TileBackground = styled.div`
  background: ${theme.backgroundSecondary};
  border: ${theme.borderColor} 1px solid;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: ${(props: any) => props.editable ? "0 .6rem .6rem" : 0};
  border-radius: 2px;
  cursor: ${(props: any) => props.editable && "pointer"};
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  ${(props: any) => props.editable && props.selected && `border: ${colors.primary} 1px solid;`}

  &&&&&& .menu {
    margin-top: 0 !important
  }

  &&&&&& .react-resizable-handle {
    position: absolute;
    right: 10;
    bottom: 10;
    ::after {
      border-color: ${theme.textPrimary};
    }
  }

  :hover {
    border: ${(props: any) => props.editable && !props.selected ? theme.borderHighlight : theme.borderColor} 1px solid;
  }
`;

export const TileTitle: any = styled.div`
  line-height: 24px;
  font-family: Lato;
  font-style: normal;
  color: ${theme.textPrimary};
`;

export const TileMenuItem: any = styled.div`
  font-weight: 600;
  font-family: Lato;
  font-style: normal;
  color: ${theme.textPrimary};
  cursor: pointer;

  :hover {
    color: ${colors.primary};
  }

  .icon {
    height: 24px;
    width: 24px;

    ::before {
      line-height: 24px;
    }
  }
`;

export const TileMenu: any = styled.div`
  ${(props: any) => props.editable && "margin: 0 -.8rem;"}
  padding: .6rem 0;
  display: flex;
  > * {
    align-self: center;
  }
`;

export const TileContent: any = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
  display: flex;
`;

export const TileIconBackground = styled(Icon)`
  color: ${theme.background};
  opacity: 0.5 !important;
  align-self: center;
`;
