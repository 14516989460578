import { Input } from "semantic-ui-react";
import styled from "styled-components";

import colors from "../../styles/colors";
import theme from "../../styles/theme";

const TextInput = styled(Input)`
  && > input:focus,
  && > input {
    background: ${theme.background};
    color: ${theme.textPrimary};
  }

  && > input {
    border-color: ${theme.borderColor};
  }

  && > input:focus {
    border-color: ${colors.primary};
  }
`;

export default TextInput;
