import * as React from "react";
import { Icon, Label, Popup } from "semantic-ui-react";

function ConnectionIndicator(props: any) {
  const { connected = false, style = {} } = props;
  return (
    <Popup
      basic
      trigger={
        <Label
          style={style}
          circular
          color={connected ? "green" : "red"}
          empty
          size="mini"
        />}>
      {connected ? "connected" : "not connected"}
    </Popup>);
}

export default ConnectionIndicator;
