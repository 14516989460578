import * as React from "react";
import styled from "styled-components";

const FieldGroupContent = styled.div<{ open?: boolean }>`
  display: ${(props: any) => props.open ? "block" : "none"};

  > * {
    display: block;
    margin: 4px 0;
  }
`;

interface INode {
  label?: string;
  description?: string;
}

class FieldGroup extends React.Component<INode & React.HTMLAttributes<HTMLDivElement>> {

  state = {
    open: true,
  };

  toggleGroup = (): void => {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { children, label, className } = this.props;
    return (
      <div className={className}>
        {
          label &&
          <React.Fragment>
            <label onClick={this.toggleGroup}>{label}</label>
            <br />
          </React.Fragment>
        }
        <FieldGroupContent open={this.state.open}>
          {children}
        </FieldGroupContent>
      </div>);
  }
}

export default styled(FieldGroup) <{ border?: boolean }>`
  margin: 12px 0;
  padding: 4px;
  border: ${(props: any) => props.border && "1px solid"};

  > label {
    margin-top: 0;
    cursor: pointer;
  }
`;
