import { Button } from "semantic-ui-react";

export default Button;

// disable this until a better solution is found

// import styled from "styled-components";
// import { pulse } from "../../styles/animations";
// import colors from "../../styles/colors";

// const getButtonColor = (primary: boolean, color: string): string => {
//   if (primary) return colors.primary;
//   if (color) return colors[color];
//   return "#cacbcd";
// };//

// export default styled(Button)`
//   &&&&&& {
//     :active, :focus {
//       animation: 0.5s ease 0s forwards ${({ primary, color }: any) =>
//     pulse(getButtonColor(primary, color))};
//     }
//   }`;
