import * as React from "react";

import * as shortid from "shortid";
import DynamicNode from "../Nodes/DynamicNode";
import RemovableNode from "../Nodes/RemovableNode";
import { field, IFormNode } from "../types";

interface IChildProps {
  children: any;
  id: any;
  type: any;
  field: any;
}

function childrenWithDynamicPath(children: any, baseId: string): any {
  return React.Children.map(children, (child: React.ReactElement<IChildProps>) => {
    if (!React.isValidElement(child)) { return child; }

    // if the child is a form field, add the new id and field path
    if (Object.values(field).includes(child.props.type)) {
      const newFieldPath = `${baseId}.${child.props.id}`;
      return React.cloneElement(child as React.ReactElement<IChildProps>, {
        children: child.props.children,
        field: newFieldPath,
        id: newFieldPath,
      });
    }

    // recursively go through the nodes and pass the id down to fields
    return React.cloneElement(child as React.ReactElement<IChildProps>, {
      children: childrenWithDynamicPath(child.props.children, baseId),
    });
  });
}

/** manage dynamic nodes */

interface IDynamicNodeHandler {
  id: string;
  DynamicNode: any;
  RemovableNode: any;
  Node: any;
  nodeProps: IFormNode;
  onAddNode: (id: string) => void;
  onRemoveNode: (id: string) => void;
  defaultNodes: string[];
  parentNode: any;
}

class DynamicNodeHandler extends React.Component<IDynamicNodeHandler, {}> {

  state = {
    nodes: this.props.defaultNodes,
  };

  public static defaultProps: Partial<IDynamicNodeHandler> = {
    DynamicNode,
    RemovableNode,
    defaultNodes: [],
    onAddNode: (id: string) => { },
    onRemoveNode: () => { },
  };

  private addNode = (): void => {
    const id = shortid.generate();
    this.setState({ nodes: [...this.state.nodes, id] });
    this.props.onAddNode(id);
  }

  private removeNode = (nodeId: string): void => {
    this.setState({ nodes: this.state.nodes.filter((id) => id !== nodeId) });
    this.props.onRemoveNode(nodeId);
  }

  render() {
    const { id, children, Node, DynamicNode, RemovableNode, nodeProps, parentNode } = this.props;
    return (
      <DynamicNode label={nodeProps.label} addNode={this.addNode} parentNode={parentNode}>
        {
          this.state.nodes
            .map((nodeId) =>
              <RemovableNode
                Node={Node}
                parentNode={parentNode}
                nodeProps={nodeProps}
                removeNode={() => this.removeNode(nodeId)}
                key={nodeId}
                id={nodeId}
                field={nodeId}
              >
                {childrenWithDynamicPath(children, `${id}.${nodeId}`)}
              </RemovableNode>)
        }
      </DynamicNode>
    );
  }
}

export default DynamicNodeHandler;
