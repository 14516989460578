import noop from "lodash.noop";
import * as React from "react";
import { Icon } from "semantic-ui-react";

import SortableList from "../SortableList/SortableList";
import TextField from "../TextField/TextField";
import { Background, ContentBar, NarrowContainer, OverflowList, SelectedItem, StyledItem, StyledListButton, Title } from "./HorizontalList.styles";

function reorder(list: any, startIndex: any, endIndex: any) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function AddListItemButton(props: any) {
  return (
    <StyledListButton {...props}>
      <Icon name="plus" />
    </StyledListButton>);
}

function ReadOnlyList(props: any) {
  const { items = [], selected, title, narrow = false, onItemSelection = noop, ...rest } = props;
  const { style, className, id } = props;

  const Container = narrow ? NarrowContainer : React.Fragment;

  return (
    <Background id={id} style={style} className={className}>
      <Container>
        <ContentBar>
          <OverflowList>
            {
              items.map((item: any, idx: number) =>
                <StyledItem
                  key={idx}
                  as={selected === item.id ? SelectedItem : "div"}
                  onClick={() => onItemSelection(item.id)}
                >
                  <TextField
                    disabled
                    onClick={() => onItemSelection(item.id)}
                    value={item.label}
                    size="mini"
                  >
                    {item.label}
                  </TextField>
                </StyledItem>)
            }
          </OverflowList>
        </ContentBar>
        <Title>
          {title}
        </Title>
      </Container>
    </Background>);
}

function EditableList(props: any) {
  const { items = [], selected, title, narrow = false } = props;
  const { onItemSelection = noop, onItemDeletion = noop } = props;
  const { onItemCreation = noop, onItemEdit = noop, onItemSort = noop } = props;
  const { style, className, id } = props;

  const onDrop = (result: any) => {
    if (!result.destination) return; // dropped outside the list
    const reorderedItems = reorder(items, result.source.index, result.destination.index);
    onItemSort(reorderedItems);
  };

  const Container = narrow ? NarrowContainer : React.Fragment;

  return (
    <Background id={id} style={style} className={className}>
      <Container>
        <ContentBar>
          <SortableList direction="horizontal" onDragEnd={onDrop}>
            <OverflowList>
              {
                items.map((item: any, idx: number) =>
                  <SortableList.Item id={`${item.id}`} index={idx} key={idx}>
                    <StyledItem
                      as={selected === item.id ? SelectedItem : "div"}
                      onClick={() => onItemSelection(item.id)}
                    >
                      <Icon
                        name="trash"
                        onClick={(e: any) => {
                          onItemDeletion(item.id);
                          e.stopPropagation();
                        }}
                      />
                      <TextField
                        onBlur={(value: string) => onItemEdit(item.id, value)}
                        edit={!item.label}
                        onClick={() => onItemSelection(item.id)}
                        value={item.label}
                        size="mini"
                      >
                        {item.label}
                      </TextField>
                    </StyledItem>
                  </SortableList.Item>)
              }
            </OverflowList>
          </SortableList>
          <AddListItemButton onClick={onItemCreation} />
        </ContentBar>
        <Title>
          {title}
        </Title>
      </Container>
    </Background>);
}

function HorizontalList(props: any) {
  const { readOnly, ...rest } = props;
  if (readOnly) return <ReadOnlyList {...rest} />;
  return <EditableList {...rest} />;
}

export default HorizontalList;
