import { Form } from "@au-re/formalizer";
import isEmpty from "lodash.isempty";
import noop from "lodash.noop";
import React from "react";

import Button from "../../../elements/Button/Button";
import BooleanField from "../components/BooleanField";
import CategoryField from "../components/CategoryField";
import ColorField from "../components/ColorField";
import DateField from "../components/DateField";
import DynamicNode from "../components/DynamicNode";
import Group from "../components/Group";
import NumberField from "../components/NumberField";
import RemovableNode from "../components/RemovableNode";
import Row from "../components/Row";
import { Tabs } from "../components/Tabs";
import TextField from "../components/TextField";
import TimeField from "../components/TimeField";

export default function DynamicForm(props: any) {
  const { formDefinition, initialValues, isLoadingData, submitOnChange = false, key } = props;
  const { setFormApi = noop, onSubmit = noop, onValueChange = noop } = props;

  return (
    <Form
      getApi={setFormApi}
      key={key || JSON.stringify(initialValues)}
      onSubmit={onSubmit}
      onValueChange={submitOnChange ? onValueChange : null}
      initialValues={initialValues}
      formDefinition={formDefinition}
      DynamicNode={DynamicNode}
      RemovableNode={RemovableNode}
      FieldTab={Tabs}
      FieldRow={Row}
      FieldGroup={Group}
      DateField={DateField}
      TimeField={TimeField}
      NumberField={NumberField}
      CategoryField={CategoryField}
      BooleanField={(props: any) => <BooleanField size="mini" {...props} />}
      ColorField={ColorField}
      TextField={(props: any) => <TextField size="mini" {...props} />}
    >
      {
        (!isEmpty(formDefinition) && !submitOnChange) &&
        <Button
          size="small"
          disabled={isLoadingData}
          loading={isLoadingData}
          primary
          type="submit"
          style={{ width: "80px", margin: "auto", display: "block" }}
        >
          submit
        </Button>
      }
    </Form>);
}
