import * as React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import color from "tinycolor2";

import colors from "../../styles/colors";
import theme from "../../styles/theme";
import SidebarContext from "./Context";

const Background: any = styled.div`
  padding: 1.1rem;
  width: 100%;
  border-radius: 2px;
  background: ${theme.backgroundTertiary};
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  :hover {
    opacity: ${(props: any) => !props.open ? 0.8 : 1};
  }
`;

const Header = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`;

const Title = styled.div`
  color: ${theme.textPrimary};
  line-height: 2rem;
  font-size: 1rem;
  flex: 1;
`;

const Content = styled.div`
  margin-top: 0.5rem;
`;

const HeadlineBackground = styled.div`
  color: ${theme.textSecondary};
  margin: 1rem 0 .5rem 0;
  font-size: 11px;
  font-weight: bold;
  .icon {
    margin-right: .5rem;
  }
`;

const Headline = (props: any) => {
  const { icon, label } = props;
  return (
    <HeadlineBackground>
      {icon && <Icon name={icon} />}{label}
    </HeadlineBackground>
  );
};

class Submenu extends React.Component<any, any> {

  static Headline = Headline;

  render() {
    const { id, title, open = false, children, headerComponent = null } = this.props;
    return (
      <SidebarContext.Consumer>
        {
          (sidebar = {} as any) => {
            const isOpen = sidebar.submenuId === null ? open : sidebar.submenuId === id;
            return (
              <Background open={isOpen}>
                <Header>
                  <Title onClick={() => sidebar.openSubmenu(id)}>{title}</Title>
                  {isOpen && headerComponent}
                </Header>
                {
                  isOpen &&
                  <Content>
                    {children}
                  </Content>
                }
              </Background>);
          }
        }
      </SidebarContext.Consumer>);
  }
}

export default Submenu;
