import noop from "lodash.noop";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import { Popup } from "semantic-ui-react";
import color from "tinycolor2";

import colors from "../../styles/colors";
import { sizes } from "../../types";
import Button from "../Button/Button";

interface IColorPickerProps {
  value?: string;
  onChange: (hex: string) => void;
  size?: sizes;
  colors?: string[];
}

class ColorPicker extends Component<IColorPickerProps, {}>  {

  state = {
    background: this.props.value,
  };

  static defaultProps = {
    value: "#f2f2f2",
    size: "medium",
    onChange: noop,
  };

  handleChangeComplete = (color: any) => {
    const { onChange } = this.props;
    this.setState({ background: color.hex });
    onChange(color.hex);
  }

  getTextColor = (backgroundColor?: string) => {
    if (color(backgroundColor).isLight()) {
      return "rgba(0,0,0,.8)";
    }
    return "#fff";
  }

  render() {
    return (
      <Popup
        style={{ padding: 0, overflow: "hidden", background: "transparent", borderRadius: "6px" }}
        position="bottom center"
        on="click"
        basic
        trigger={
          <span style={{ display: "inline-block" }}>
            <Button
              type="button"
              size={this.props.size}
              style={{
                background: this.state.background,
                color: this.getTextColor(this.state.background),
              }}
            >
              {this.state.background}
            </Button>
          </span>}
      >
        <SketchPicker
          disableAlpha
          presetColors={this.props.colors || Object.values(colors)}
          color={this.state.background}
          onChangeComplete={this.handleChangeComplete}
        />
      </Popup>

    );
  }
}

export default ColorPicker;
