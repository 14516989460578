import { Form } from "informed";
import React from "react";
import styled from "styled-components";

import BooleanField from "../components/BooleanField";
import CategoryField from "../components/CategoryField";
import ColorField from "../components/ColorField";
import Label from "../components/Label";
import Link from "../components/Link";
import NumberField from "../components/NumberField";
import Section from "../components/Section";
import TextAreaField from "../components/TextAreaField";
import TextInput from "../components/TextField";

const StyledForm: any = styled(Form)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

class StandardForm extends React.Component<any, any> {
  static Text = TextInput;
  static Label = Label;
  static Section = Section;
  static Checkbox = BooleanField;
  static Dropdown = CategoryField;
  static TextArea = TextAreaField;
  static Link = Link;
  static Number = NumberField;
  static Color = ColorField;

  render() {
    const { children, ...rest } = this.props;
    return (<StyledForm {...rest}>{children}</StyledForm>);
  }
}

export default StandardForm;
