import * as React from "react";
import { Icon, Menu, Popup } from "semantic-ui-react";
import styled from "styled-components";

import theme from "../../styles/theme";
import SidebarContext from "./Context";
import SidebarFooter from "./Footer";
import SidebarItem from "./Item";
import Submenu from "./Submenu";

const Background: any = styled.div`
  background: ${theme.backgroundSecondary};
  width: ${(props: any) => (props.open ? "420px" : "50px")};
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: width .2s;
  z-index: 10;
  color: ${theme.textSecondary};

  .ui.divider {
    margin: 0;
  }
`;

const SidebarMenu = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const Submenus = styled.div`
  flex: 1;
  padding: 1rem 1rem 0 1px;
  overflow: auto;

  > * {
    margin-bottom: .5rem;
  }
`;

const SubmenuHeadline = styled.div`
  flex: 1;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${theme.textPrimary};
  display: flex;
  justify-content: flex-end;
`;

const IconMenu = (props: any) => {
  const { children, style, className } = props;
  return (
    <Menu icon vertical secondary style={style} className={className}>
      {children}
    </Menu>
  );
};

const SidebarMenuContent = (props: any) => {
  const { id, children } = props;

  return (
    <SidebarContext.Consumer>
      {
        (value = {} as any) => {
          if (value.menuId !== id || !value.open) return null;
          return (
            <Submenus>
              {children}
            </Submenus>);
        }
      }
    </SidebarContext.Consumer>);
};

const SidebarIcons = styled(IconMenu)`
  &&&& {
    min-width: 51px;
    margin-left: 0;
    margin-right: 0;

    .item {
      color: ${theme.textSecondary};
    }

    .item.active {
      color: ${theme.textPrimary};
    }

    .item.active:hover,
    .item:hover {
      color: ${theme.textPrimary};
    }
  }
`;

class SidebarProvider extends React.Component<any, any> {

  state = {
    open: this.props.open || false,
    menuId: this.props.defaultMenu || null,
    submenuId: null,
  };

  openMenu = (menuId = this.props.defaultMenu) => {
    this.setState({ menuId, submenuId: null, open: true });
  }

  closeMenu = () => {
    this.setState({ menuId: null, submenuId: null });
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open, menuId: (!this.state.open ? this.state.menuId : null) });
  }

  openSidebar = () => {
    this.setState({ open: true });
  }

  openSubmenu = (submenuId: string) => {
    this.setState({ submenuId });
  }

  render() {
    return (
      <SidebarContext.Provider
        value={{
          open: this.state.open,
          menuId: this.state.menuId,
          submenuId: this.state.submenuId,
          toggleOpen: this.toggleOpen,
          openMenu: this.openMenu,
          openSidebar: this.openSidebar,
          closeMenu: this.closeMenu,
          openSubmenu: this.openSubmenu,
        }}
      >
        {this.props.children}
      </SidebarContext.Provider>);
  }
}

class Sidebar extends React.Component<any, any> {

  static Provider = SidebarProvider;
  static Consumer = SidebarContext.Consumer;
  static Context = SidebarContext;
  static Menu = SidebarMenu;
  static Icons = SidebarIcons;
  static MenuContent = SidebarMenuContent;
  static Footer = SidebarFooter;
  static Item = SidebarItem;
  static Submenu = Submenu;
  static SubmenuHeadline = SubmenuHeadline;

  render() {
    return (
      <SidebarContext.Consumer>
        {
          (sidebarContext = {} as any) =>
            <Background id={this.props.id} style={this.props.style} open={sidebarContext.open}>
              {this.props.children}
            </Background>
        }
      </SidebarContext.Consumer>);
  }
}

export default Sidebar;
