import { Container } from "semantic-ui-react";
import styled from "styled-components";
import color from "tinycolor2";

import colors from "../../styles/colors";
import theme from "../../styles/theme";

const height = "2.6rem";

export const Background = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${height};
  background: ${theme.backgroundSecondary};
  color: ${theme.textSecondary};
  overflow: hidden;
`;

export const NarrowContainer = styled(Container)`
  &&&&&& {
    display: flex;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  font-family: Quicksand, Lato, sans-serif;
  line-height: ${height};
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 1rem;
  color: ${theme.textSecondary};
`;

export const StyledItem = styled.div`
  height: ${height};
  line-height: ${height};
  font-size: 1rem;
  min-width: 8rem;
  padding: 0 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.textPrimary};
  background: ${theme.backgroundSecondary};
  border-bottom: 2px solid transparent;
  overflow: hidden;
  cursor: pointer;

  .icon {
    cursor: pointer;
    :hover {
      color: ${colors.red};
    }
  }

  :hover {
    background: ${theme.background};
    opacity: 0.8;
  }
`;

export const SelectedItem = styled(StyledItem)`
  background: ${theme.background};
  border-color: ${colors.primary};

  :hover {
    background: ${theme.background};
  }
`;

export const ContentBar = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const OverflowList = styled.div`
  display: flex;
  overflow: auto;
  height: calc(height + 17px);
  max-width: 100%;
`;

export const StyledListButton = styled.div`
  cursor: pointer;
  width: ${height};
  height: ${height};
  min-width: ${height};
  background: ${colors.primary};
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: center;

  :hover {
    background: ${color(colors.primary).brighten(3).toString()};
  }

  .icon {
    margin: 0;
    padding: 0;
    align-self: center;
  }
`;
