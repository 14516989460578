import noop from "lodash.noop";
import React from "react";
import { Input } from "semantic-ui-react";
import styled, { css } from "styled-components";
import theme from "../../styles/theme";

interface ITextField extends React.HTMLAttributes<HTMLInputElement> {
  edit?: boolean;
  disabled?: boolean;
  value?: string;
  transparent?: boolean;
  size?: any;
  onBlur: any;
  onClick?: any;
}

const InputField = styled(Input)`
  input {
    background: ${theme.background} !important;
    color: ${theme.textPrimary} !important;
  }

  && input:focus {
    border: none;
    ${(props) => !props.transparent && css`
      background: rgba(0,0,0,.05) !important;
      border-width: 1px;
      border-style: dashed;
      border-color: ${theme.borderHighlight} !important;
    `}
  }

  && input::placeholder {
    color: ${theme.textPrimary};
  }
`;

// become TextField after a double click, saves on blur or enter

class TextField extends React.Component<ITextField, {}> {

  count = 0;
  timeout: any;

  state = {
    edit: this.props.edit || !this.props.value,
    value: this.props.value,
  };

  static defaultProps = {
    onBlur: noop,
    onClick: noop,
    edit: false,
    size: "small",
  };

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  handleClick = (e: any) => {
    if (this.props.disabled) return;

    e.stopPropagation();
    if (this.timeout) clearTimeout(this.timeout);

    this.count++;

    if (this.count === 2) {
      this.count = 0;
      this.setState({ edit: true });
    } else {
      this.timeout = setTimeout(
        () => {
          this.count = 0;
          this.props.onClick();
        },
        400);
    }
  }

  handleKeyPress = (e: any) => {
    // resize input box to input content
    this.setState({ value: e.target.value });

    // blur out on enter
    if (e.key === "Enter") {
      const { onBlur } = this.props;
      onBlur(e.target.value);
      if (e.target.value) {
        this.setState({ edit: false });
      }
    }
  }

  handleBlur = (e: any) => {
    const { onBlur } = this.props;
    onBlur(e.target.value);
    if (e.target.value) {
      this.setState({ edit: false });
    }
  }

  render() {
    const { children, value, className, style = {}, transparent, placeholder, size } = this.props;
    const { edit } = this.state;

    // edit mode
    if (edit) {
      return (
        <InputField
          transparent={transparent}
          placeholder={placeholder}
          style={style}
          className={className}
          defaultValue={value}
          size={size}
          autoFocus
          onKeyPress={this.handleKeyPress}
          onBlur={this.handleBlur}
        />
      );
    }

    // view mode
    return (
      <span onClick={this.handleClick} className={className} style={style}>
        {children}
      </span>
    );
  }
}

export default TextField;
