import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

import colors from "../../styles/colors";

const Background = styled.div`
  border-top: 1px solid ${colors.grey1};
  background: white;
  height: 86px;
  min-height: 70px;
  position: relative;
`;

const Title = styled.div`
  text-transform: lowercase;
  font-family: Quicksand, Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  font-size: 16px;
  padding-top: 13px;
  text-align: center;
  color: ${colors.fontBright};
`;

const Notice = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  font-size: 14px;
  color: ${colors.fontBright};
  position: absolute;
  right: 13px;
  bottom: 13px;

  &&&&&& a {
    color: ${colors.fontBright};
    :hover {
      cursor: pointer;
      color: ${colors.font};
      text-decoration: underline;
      text-decoration-style: dashed;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  >* {
    margin-right: 1rem;
  }
`;

const Link = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-family: Lato;
  color: ${colors.fontBright};

  :hover {
    color: ${colors.font};
    text-decoration: underline;
    text-decoration-style: dashed;
  }
`;

class Footer extends Component {

  static Title = Title;
  static Menu = Menu;
  static Link = Link;
  static Notice = Notice;

  render() {
    return (
      <Background>
        <Container style={{ position: "relative", height: "79px" }}>
          {this.props.children}
        </Container>
      </Background>);
  }
}

export default Footer;
