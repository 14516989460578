import brace from "brace"; // required for styling

import React from "react";
import AceEditor from "react-ace";
import styled from "styled-components";

const CodeEditor = ({ id, height = "100%", width = "100%", ...props }: any) => {
  return (
    <AceEditor
      {...props}
      height={height}
      width={width}
      name={id}
      editorProps={{ $blockScrolling: true }}
    />);
};

export default styled(CodeEditor)``;
