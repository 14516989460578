import "./ReactToastify.css";

import React from "react";
import { cssTransition, Slide as _Slide, toast, ToastContainer } from "react-toastify";
import { Header, Icon, Message } from "semantic-ui-react";

export const AlertContainer = ToastContainer;
export const Alert = toast;
export const Slide = cssTransition({
  enter: "Toastify__slide-enter",
  exit: "Toastify__slide-exit",
  duration: 180,
  appendPosition: true,
});

const DefaultMessage = (props: any) => {
  const { title, icon, description, children } = props;
  return (
    <div style={{ padding: "1rem 0" }}>
      <Header as={"h5"} inverted>
        <Icon inverted name={icon} />
        <Header.Content>
          {title}
          <Header.Subheader>{description}</Header.Subheader>
          <div style={{ paddingTop: "8px" }}>
            {children}
          </div>
        </Header.Content>
      </Header>
    </div>
  );
};

export const AlertMessage = (props: any) => {
  const { type, ...rest } = props;
  if (type === "success") return <Message {...rest} icon="check circle" />;
  if (type === "error") return <Message {...rest} icon="warning circle" />;
  if (type === "info") return <Message {...rest} icon="info circle" />;
  return <DefaultMessage {...rest} />;
};
