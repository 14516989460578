import React from "react";
import styled from "styled-components";

import { pulse } from "../../styles/animations";
import colors from "../../styles/colors";
import theme from "../../styles/theme";

interface ICheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  size?: string;
  defaultChecked?: boolean;
  inverted?: boolean;
}

const CustomCheckmark = styled.span`
  border: 1px solid transparent;
  border-radius: 4px;
  position: absolute;
  top: ${(props) => (props.size === "mini" ? "6px" : "3px")};
  left: ${(props) => (props.size === "mini" ? "6px" : "3px")};
  height: ${(props) => (props.size === "mini" ? "18px" : "24px")};
  width: ${(props) => (props.size === "mini" ? "18px" : "24px")};
  transition: all 0.2s ease-in-out;
  background: ${theme.borderColor};

  /* "checked" symbol */
  :after {
    content: "";
    position: absolute;
    display: none;
    left: ${(props: ICheckboxProps) => (props.size === "mini" ? "6px" : "9px")};
    top: ${(props: ICheckboxProps) => (props.size === "mini" ? "3px" : "5px")};
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const DefaultCheckmark = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  :checked ~ ${CustomCheckmark} {
    background: ${colors.primary};
    animation: 0.5s ease 0s forwards ${pulse(colors.primary)};
  }

  /* Show the checkmark when checked */
  :checked ~ ${CustomCheckmark}:after {
    display: block;
  }
`;

const Label = styled.div`
  color: ${theme.textSecondary};
  padding-left: ${(props: ICheckboxProps) => (props.size === "mini" ? "28px" : "32px")};
  line-height: ${(props: ICheckboxProps) => (props.size === "mini" ? "30px" : "28px")};
  font-size: ${(props: ICheckboxProps) => (props.size === "mini" ? ".8rem" : "1rem")};
`;

const CheckboxBackground = styled.label`
  display: inline-block;
  position: relative;
  height: 30px;
  min-width: 30px;
  cursor: pointer;
  user-select: none;

  :hover ${CustomCheckmark} {
    border-color: ${colors.primary};
  }
`;

const CheckboxContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const Checkbox = (props: ICheckboxProps) => {
  const { label, className, style, size, defaultChecked = false, defaultValue, inverted, ...rest } = props;
  return (
    <CheckboxBackground style={style}>
      <CheckboxContent>
        {label && <Label inverted={inverted} className="checkbox-label" size={size}>{label}</Label>}
        <DefaultCheckmark type="checkbox" defaultChecked={defaultChecked} {...rest} />
        <CustomCheckmark inverted={inverted} size={size} />
      </CheckboxContent>
    </CheckboxBackground>);
};

export default Checkbox;
