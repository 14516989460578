import * as React from "react";
import styled from "styled-components";

import { IFormNode } from "../types";

const FieldTabRow = styled.div`
  border: 1px solid;
  margin: 0;
`;

const FieldTabButton = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  display: inline-block;
  margin: -1px;
  padding: 4px;
  background: ${(props: any) => props.selected && "#e2e2e2"};
  border: ${(props: any) => props.selected ? "1px solid" : "1px solid transparent"};
`;

const FieldTabContent = styled.div<{ selected?: boolean }>`
  display: ${(props: any) => props.selected ? "block" : "none"};

  > * {
    display: block;
    margin: -1px 0 0 0;
  }
`;

class FieldTab extends React.Component<IFormNode & React.HTMLAttributes<HTMLDivElement>> {

  public state = {
    selected: 0,
  };

  private selectTab = (event: any): void => {
    const { tabIndex } = event.target.dataset;
    this.setState({ selected: parseInt(tabIndex, 10) });
  }

  public render() {
    const { children, className } = this.props;
    const labels = React.Children.map(children, (child: any) => child.props.label);

    return (
      <div className={className}>
        <FieldTabRow>
          {
            labels.map((label: string, idx) =>
              <FieldTabButton
                key={idx}
                data-tab-index={idx}
                selected={this.state.selected === idx}
                onClick={this.selectTab}
              >
                {label}
              </FieldTabButton>)
          }
        </FieldTabRow>
        {
          React.Children.map(children, (child: any, idx) => {
            const { label, ...rest } = child.props;
            return (
              <FieldTabContent selected={this.state.selected === idx} key={idx}>
                {React.createElement(child.type, rest)}
              </FieldTabContent>);
          })
        }
      </div>);
  }
}

export default styled(FieldTab) <{ border?: boolean }>`
  margin: 12px 0;
  border: ${(props) => props.border && "1px solid"};

  > label {
    margin-top: 0;
    cursor: pointer;
  }
`;
