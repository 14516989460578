import * as React from "react";
import styled from "styled-components";

class DropField extends React.Component {

  onDrop = (event: any) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("text");
    event.target.appendChild(document.getElementById(data));
  }

  render() {
    return (<div onDrop={this.onDrop} {...this.props} />);
  }
}

export default styled(DropField)`
  height: 2rem;
  width: 150px;
  background: #e2e2e2;
  border: 1px dashed;
`;
