import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import colors from "../../styles/colors";
import theme from "../../styles/theme";

const Image = styled.img`
  height: 100%;
  align-self: center;
  object-fit: cover;

  :hover {
    opacity: 0.9;
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 7rem;
  overflow: hidden;
  align-self: center;
  background: white;
`;

const Content = styled.div`
  align-self: center;
  overflow: hidden;
`;

const Background: any = styled.label`
  overflow: hidden;
  width: ${(props: any) => (props.size === "mini" ? "3.1rem" : "7rem")};
  min-width: ${(props: any) => (props.size === "mini" ? "3.1rem" : "7rem")};
  height: ${(props: any) => (props.size === "mini" ? "3.1rem" : "7rem")};
  padding: ${(props: any) => (props.size === "mini" ? "4px" : "8px")};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
  display: flex;
  justify-content: center;
  align-self: center;

  color: ${theme.textPrimary};
  background: ${theme.backgroundTertiary};
  font-size: 1rem;

  border: 1px solid ${theme.borderColor};
  border-radius: 4px;

  :hover {
    border: 1px dashed ${colors.primary};
    color: ${colors.primary};
  }

  input[type=file] {
    position:absolute;
    top: -1000px;
  }
`;

interface IImageUploadProps extends React.HTMLAttributes<HTMLLabelElement> {
  src?: string;
  size?: string;
  message?: string;
  withIcon?: boolean;
  onUpload: (e: any) => void;
}

class ImageUpload extends Component<IImageUploadProps> {

  static defaultProps = {
    onUpload: () => { },
  };

  render() {
    const { style, src, message, onUpload, children, size, withIcon } = this.props;
    return (
      <Background style={style} size={size}>
        {
          src &&
          <ImageWrapper>
            <Image src={src} />
          </ImageWrapper>
        }
        {
          !src &&
          <Content>
            {withIcon && <Icon name="image" />}
            <div>{message}</div>
          </Content>
        }
        {children}
        <input
          type="file"
          accept="image/*"
          onChange={(e: any) => onUpload(e.target.files[0])}
        />
      </Background>);
  }
}

export default ImageUpload;
