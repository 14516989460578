import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const RemoveNodeIcon = styled(Icon)`
  && {
    color: rgba(0,0,0,.6);
    position: ${(props) => props.parentNode === "group" && "absolute"} !important;
    top: ${(props) => props.parentNode === "group" && "4px"};
    right: ${(props) => props.parentNode === "group" && "4px"};
    cursor: pointer;
    line-height: ${(props) => props.parentNode === "row" && "62px"};
    font-size: 1.1rem;
    :hover {
      color: red;
    }
  }
`;

function RemovableNode(props: any) {
  const { children, removeNode, Node, nodeProps, parentNode } = props;
  return (
    <Node {...nodeProps}>
      <RemoveNodeIcon name="trash" parentNode={parentNode} onClick={removeNode} />
      {children}
    </Node>
  );
}

export default RemovableNode;
