import React from "react";
import { Button, Icon } from "semantic-ui-react";

function DynamicNode(props: any) {
  const { children, addNode, label } = props;
  return (
    <React.Fragment>
      {children}
      <Button
        style={{ maxHeight: "3rem", display: "block", margin: "0 auto" }}
        type="button"
        icon
        labelPosition="left"
        onClick={addNode}
      >
        <Icon name="plus" />
        {label}
      </Button>
    </React.Fragment>
  );
}

export default DynamicNode;
