import { css } from "styled-components";

const sizes = {
  desktop: 1199,
  tablet: 991,
  mobile: 767,
};

export default Object.keys(sizes).reduce((acc: any, label: any) => {
  acc[label] = (...args: any) => css`
     @media (max-width: ${(sizes as any)[label]}px) {
        ${(css as any)(...args)};
     }
  `;
  return acc;
}, {});
