(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"), require("flat"), require("informed"), require("lodash.set"), require("lodash.get"), require("lodash.merge"), require("lodash.unset"), require("shortid"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "styled-components", "flat", "informed", "lodash.set", "lodash.get", "lodash.merge", "lodash.unset", "shortid"], factory);
	else if(typeof exports === 'object')
		exports["formalizer"] = factory(require("react"), require("styled-components"), require("flat"), require("informed"), require("lodash.set"), require("lodash.get"), require("lodash.merge"), require("lodash.unset"), require("shortid"));
	else
		root["formalizer"] = factory(root["react"], root["styled-components"], root["flat"], root["informed"], root["lodash.set"], root["lodash.get"], root["lodash.merge"], root["lodash.unset"], root["shortid"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__18__) {
return 