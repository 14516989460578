import "rc-slider/assets/index.css";

import { asField } from "informed";
import RCNumberInput from "rc-input-number";
import RCSlider from "rc-slider";
import React, { Fragment } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import { pulse } from "../../../styles/animations";
import colors from "../../../styles/colors";
import Label from "./Label";
import Notification from "./Notification";
import Popup from "./Popup";

const SliderWithTooltip = (RCSlider as any).createSliderWithTooltip(RCSlider);
const RangeSliderWithTooltip = (RCSlider as any).createSliderWithTooltip((RCSlider as any).Range);

const Slider = ({ range, ...rest }: any) => {
  if (range) return <RangeSliderWithTooltip {...rest} />;
  return (<SliderWithTooltip {...rest} />);
};

const StyledSlider = styled(Slider)`

  .rc-slider-mark-text {
    width: auto !important;
    margin-top: -5px !important;
    margin-left: -5px !important;
  }

  .rc-slider-handle {
    border-color: ${colors.primary};

    :active, :hover, :focus {
      border-color: ${colors.primary};
      box-shadow: 0 0 5px ${colors.primary};
    }
  }

  .rc-slider-dot-active {
    border-color: ${colors.primary};
  }

  .rc-slider-track {
    background: ${colors.primary};
  }
`;

/* custom number input styled based on antd input number */

const upHandler = (<Icon name="triangle up" style={{ margin: "-4px 0 0 0" }} />);
const downHandler = (<Icon name="triangle down" style={{ margin: "-4px 0 0 0" }} />);

const NumberInput = (props: any) => (
  <RCNumberInput
    upHandler={upHandler}
    downHandler={downHandler}
    {...props}
  />);

const StyledNumberInput = styled(NumberInput)`
  margin: 0;
  padding: 0;
  line-height: 26px;
  font-size: 12px;
  height: ${(props) => (props.size === "mini" ? "30px" : "40px")};
  width: 148px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  transition: all 0.1s ease-in;

  :focus-within, :hover, :active {
    border-color: ${({ disabled }) => !disabled && colors.primary};
  }

  :focus-within, :active {
    animation: 0.5s ease 0s ${({ disabled }) => !disabled && pulse(colors.primary)};
  }

  .rc-input-number-handler-wrap {
    float: right;
    border-left: 1px solid #D9D9D9;
    width: 24px;
    height: 100%;
    display: ${({ disabled }) => (disabled && "none")};
  }

  .rc-input-number-handler {
    text-align: center;
    line-height: ${(props) => (props.size === "mini" ? "15px" : "20px")};
    height: ${(props) => (props.size === "mini" ? "14px" : "19px")};;
    overflow: hidden;
    display: block;
    touch-action: none;
    cursor: ${({ disabled }) => (!disabled ? "pointer" : "not-allowed")};
  }

  .rc-input-number-handler.rc-input-number-handler-up,
  .rc-input-number-handler.rc-input-number-handler-down {

    color: #b1b1b1;

    :hover {
      background: ${colors.primary};
      color: white;
    }
  }

  .rc-input-number-handler.rc-input-number-handler-down-disabled,
  .rc-input-number-handler.rc-input-number-handler-up-disabled {
    background-color: #e2e2e2;
    color: #b1b1b1;

    :hover {
      color: #b1b1b1;
      background-color: #e2e2e2;
      cursor: not-allowed;
    }
  }

  .rc-input-number-input-wrap {
    overflow: hidden;
    height: 100%;
  }

  .rc-input-number-input {
    width: 100%;
    text-align: center;
    outline: 0;
    line-height: 35px;
    height: 100%;
    transition: all 0.3s ease;
    border: 0;
    padding: 0;

    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    color: ${({ disabled }) => (disabled ? "rgba(0, 0, 0, 0.25)" : "#666666")};
    background: ${({ disabled }) => (disabled ? "#e2e2e2" : "white")};
  }
`;

const NumberSlider = asField(({
  fieldState,
  fieldApi,
  withSlider,
  withErrorMessage,
  ...props }: any) => {

  const { value, error } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const {
    onChange,
    onBlur,
    forwardedRef,
    defaultValue,
    range,
    slider,
    inputBox = true,
    min = 0,
    max = 100,
    marks = {},
    ...rest } = props;

  const cleanMarks = Object.keys(marks).filter((key) => {
    const parsed = parseInt(key, 10);
    return !isNaN(parsed) && typeof parseInt(key || "", 10) === "number";
  }).reduce((acc, val) => Object.assign(acc, { [val]: marks[val] }), {});

  const withMinMax = {
    [min]: min,
    [max]: max,
  };

  let sliderDefaultValue = defaultValue;

  if (range && !Array.isArray(sliderDefaultValue)) {
    sliderDefaultValue = [min, max];
  }

  return (
    <Fragment>
      <div style={Object.assign({ display: "flex" }, slider ? { flex: 1 } : {})}>
        {(slider || range) && <StyledSlider
          range={range}
          min={min}
          max={max}
          value={!value && value !== 0 ? sliderDefaultValue : value}
          marks={Object.assign(cleanMarks, withMinMax)}
          style={{ margin: "0px 24px 0px 0px" }}
          onChange={(value: any) => {
            setValue(value);
            if (onChange) onChange(value);
          }}
          {...rest}
        />
        }
        {inputBox && !range && <StyledNumberInput
          {...rest}
          min={min}
          max={max}
          ref={forwardedRef}
          value={!value && value !== 0 ? defaultValue : value}
          error={error}
          onChange={(value: any) => {
            setValue(value);
            if (onChange) onChange(value);
          }}
          onBlur={(value: any) => {
            setTouched();
            if (onBlur) onBlur(value);
          }}
        />}
      </div>
      {withErrorMessage && <Notification>{error}</Notification>}
    </Fragment>);
});

const NumberField = (props: any) => {
  const { id, parentNode, description, label } = props;
  const { defaultValue, step, range, min, max, marks, inputBox, slider } = props;
  const withSlider = (typeof min === "number" && typeof max === "number") || range === true;

  return (
    <Popup
      description={description}
      trigger={
        <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
          {(label || parentNode === "row") && <Label htmlFor={id}>{label}</Label>}
          <NumberSlider
            inputBox={inputBox}
            slider={slider}
            marks={marks}
            defaultValue={defaultValue}
            size="mini"
            withSlider={!!withSlider}
            range={range}
            field={id}
            id={id}
            step={step}
            min={min}
            max={max}
          />
        </div>}
    />);
};

export default NumberField;
