import React from "react";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import noop from "lodash.noop";

import colors from "../../styles/colors";

const getInitials = (name?: string) => {
  if (!name) return "";
  const initials = name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g) || [name];
  return initials.join("");
};

interface IAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  src?: string;
  name?: string;
  inverted?: boolean;
  size?: string;
}

interface IAvatarBackground extends React.HTMLAttributes<HTMLDivElement> {
  withBorder?: boolean;
  inverted?: boolean;
}

const AvatarImage = styled(Image)`
  &&&& {
    height: 100%;
    margin: 0;
  }
`;

const Background = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 60px;
  line-height: 27px;
  ${({ inverted, withBorder }: IAvatarBackground) =>
    withBorder && `border: 1px solid ${inverted ? "white" : colors.black};`}
  ${({ inverted }: IAvatarBackground) => `color: ${inverted ? "white" : colors.black};`}
  text-align: center;
  font-weight: bold;
  font-size: .9rem;
`;

const Avatar = (props: IAvatarProps) => {
  const { src, inverted, name, style, className, id, size = "mini", onClick = noop } = props;
  return (
    <Background
      onClick={onClick}
      id={id}
      className={className}
      style={style}
      inverted={inverted}
      withBorder={!src}
    >
      {src && <AvatarImage avatar size={size} src={src} />}
      {!src && getInitials(name)}
    </Background>);
};

export default Avatar;
