import { asField } from "informed";
import React, { Fragment } from "react";

import ColorPicker from "../../../elements/ColorPicker/ColorPicker";
import Label from "./Label";
import Notification from "./Notification";

const colors = [
  "#009374",
  "#12939A",
  "#79C7E3",
  "#1A3177",
  "#FF9833",
  "#EF5D28",
  "#19CDD7",
  "#DDB27C",
  "#88572C",
  "#DA70BF",
  "#125C77",
  "#4DC19C",
  "#776E57",
  "#17B8BE",
  "#F6D18A",
  "#B7885E",
  "#FFCB99",
  "#F89570",
  "#829AE3",
  "#E79FD5",
  "#1E96BE",
  "#89DAC1",
  "#B3AD9E",
];

const ColorInput = asField(({ fieldState, fieldApi, withErrorMessage, size, ...props }: any) => {
  const { value, error } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, forwardedRef, defaultValue, ...rest } = props;

  return (
    <Fragment>
      <ColorPicker
        {...rest}
        size={size}
        ref={forwardedRef}
        value={!value && value !== 0 ? defaultValue : value}
        error={error}
        onChange={(hex) => {
          setValue(hex);
          if (onChange) onChange(hex);
        }}
        onBlur={(e: any) => {
          setTouched();
          if (onBlur) onBlur(e);
        }}
      />
      {withErrorMessage && <Notification>{error}</Notification>}
    </Fragment>);
});

const ColorField = ({ id, label, parentNode, _colors = colors, ...rest }: any) => {
  return (
    <div style={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
      {(label || parentNode === "row") && <Label htmlFor={id}>{label}</Label>}
      <ColorInput size="mini" field={id} id={id} {...rest} colors={_colors} />
    </div>);
};

export default ColorField;
