import styled from "styled-components";

const Notification = styled.div`
  font-size: 0.8rem;
  line-height: 1rem;
  height: 1.25rem;
  color: #E44E40;
`;

export default Notification;
