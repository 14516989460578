export enum collection {
  group = "group",
  tab = "tab",
  row = "row",
}

export enum field {
  number = "number",
  text = "text",
  color = "color",
  dropzone = "dropzone",
  category = "category",
  boolean = "boolean",
  date = "date",
  time = "time",
}

export interface IFormCollection {
  type: collection;
  elements?: Array<IFormField | IFormCollection>;
  label?: string;
  id?: string;
  parentNode?: collection;
  description?: string;
  dynamic?: boolean;
}

export interface IBaseField {
  type: field;
  id: string;
  parentNode?: collection;
  label?: string;
  description?: string;
}

export interface ITextField extends IBaseField {
  defaultValue?: string;
  placeholder?: string;
}

export interface INumberField extends IBaseField {
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number;
  placeholder?: number;
}

export interface IColorField extends IBaseField {
  color?: string;
  defaultValue?: string;
}

export interface IBooleanField extends IBaseField {
  defaultValue?: boolean;
}

export interface ICategoryField extends IBaseField {
  values: ICategoryItem[];
  defaultValue?: string;
}

export interface ICategoryItem {
  id: string;
  label?: string;
}

export type IFormField = ITextField | INumberField | IColorField | IBooleanField | ICategoryField;
export type IFormNode = IFormField | IFormCollection;

export const isField = (node: IFormNode) => Object.values(field).includes(node.type);
export const isCollection = (node: IFormNode) => !!(node as IFormCollection).elements;
export const isDynamic = (node: IFormNode) => !!(node as IFormCollection).dynamic;
export const isCategory = (node: IFormNode) => node.type === field.category;

export interface IDefaultFields {
  [field.number]: number;
  [field.text]: string;
  [field.color]: string;
  [field.dropzone]: { label: string, value: string };
  [field.boolean]: boolean;
  [field.category]: number;
  [field.date]: string;
  [field.time]: string;
}

// default values for incomplete forms values
export const defaultFieldValues: IDefaultFields = {
  [field.text]: "",
  [field.boolean]: false,
  [field.color]: "#000",
  [field.number]: 0,
  [field.category]: 0,
  [field.date]: "1990-01-01",
  [field.time]: "",
  [field.dropzone]: { label: "", value: "" },
};
