import * as React from "react";
import styled from "styled-components";

interface IDynamicNode {
  addNode: () => void;
  label: string;
}

/* can add nodes of type Node */

class DynamicNode extends React.Component<IDynamicNode, {}> {
  render() {
    const { children, addNode, label } = this.props;
    return (
      <React.Fragment>
        {children}
        <div>
          <button onClick={addNode}>add {label}</button>
        </div>
      </React.Fragment>
    );
  }
}

export default styled(DynamicNode)``;
