import "highlight.js/styles/docco.css";
import "katex/dist/katex.min.css";
import "./RichText.css";

import highlighter from "highlight.js/lib/highlight";
import bash from "highlight.js/lib/languages/bash";
import css from "highlight.js/lib/languages/css";
import javascript from "highlight.js/lib/languages/javascript";
import json from "highlight.js/lib/languages/json";
import python from "highlight.js/lib/languages/python";
import xml from "highlight.js/lib/languages/xml";

import mk from "@iktakahiro/markdown-it-katex";
import md from "markdown-it";
import React from "react";

highlighter.registerLanguage("python", python);
highlighter.registerLanguage("bash", bash);
highlighter.registerLanguage("json", json);
highlighter.registerLanguage("xml", xml);
highlighter.registerLanguage("css", css);
highlighter.registerLanguage("javascript", javascript);

const markdown = md({
  html: true,
  highlight: (str, lang) => {
    if (!lang || !highlighter.getLanguage(lang)) return "";
    try {
      return `<pre class="hljs"><code>${highlighter.highlight(lang, str, true).value}</code></pre>`;
    } catch (err) {
      throw err;
    }
  },
}).use(mk);

function RichText(props: any) {
  const { text = "", ...rest } = props;
  const html = markdown.render(text);
  return (<div {...rest} className="richtext" dangerouslySetInnerHTML={{ __html: html }} />);
}

export default RichText;
