import React, { Component } from "react";
import { Button, Popup } from "semantic-ui-react";
import styled, { css } from "styled-components";

import colors from "../../styles/colors";
import theme from "../../styles/theme";

const height = "48px";

const BaseDiv = (props: any) => {
  const { inverted, primary, children, ...rest } = props;
  return <div {...rest}>{children}</div>;
};

const HeaderTitle = styled(BaseDiv)`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  font-size: 20px;
  opacity: .8;
  color: ${theme.textPrimary};
  align-self: center;
  padding: 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderSubTitle = styled(BaseDiv)`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: .6rem;
  font-size: 10px;
  opacity: .5;
  color: ${theme.textSecondary};
  align-self: center;
  padding: 0 2px;
`;

const HeaderBackground = styled(BaseDiv)`
  position: relative;
  display: flex;
  height: ${height};
  min-height: ${height};
  padding: 0 4px;
  background: ${theme.backgroundSecondary};
  align-items: center;

  > *:not(:first-child) {
    margin-left: 1rem;
  }

  > :last-child {
    margin-right: 1rem;
  }

  && .ui.menu {
    border: none;
    margin: 0 0 -1px 0;
  }

  && .ui.search {
    align-self: center;
  }

  && .ui.button {
    padding: .8rem;
    border: none;
    box-shadow: none;
    color: ${theme.textPrimary};
    background: ${theme.backgroundTertiary};
  }

  && .ui.button.primary {
    color: white;
    background: ${colors.primary};
  }

  .ui.secondary.pointing.menu .item {
    border-bottom-width: 1px;
  }
`;

const HeaderButtonBackground: any = styled(Button).attrs((props) => ({
  basic: !props.primary,
  icon: true,
}))`
  &&&&&&&& {
    border-radius: 0;
    height: ${height};
    min-height: ${height};
    min-width: ${height};
    margin: 0;
    box-shadow: none !important;
    color: ${theme.textSecondary} !important;

    ${(props) => props.primary && css`
      color: white !important;
      background: ${colors.primary} !important;
    `}

    ${(props) => props.active && css`
      color: ${theme.textPrimary} !important;
      border-top: 4px solid ${colors.primary};
      background: ${theme.backgroundTertiary} !important;
    `}

    :hover {
      background: ${theme.backgroundTertiary} !important;
      color: ${theme.textPrimary} !important;
    }
  }
`;

function HeaderButton(props: any) {
  const { label, ...rest } = props;
  return (
    <Popup
      position="bottom center"
      content={label}
      trigger={
        <div style={{ margin: 0 }}>
          <HeaderButtonBackground {...rest} />
        </div>
      }
    />
  );
}

class PageHeader extends Component<any, any> {

  static Title = HeaderTitle;
  static SubTitle = HeaderSubTitle;
  static Button = HeaderButton;

  render() {
    const { children, primary, ...rest } = this.props;
    return (
      <HeaderBackground {...rest}>
        {children}
      </HeaderBackground>);
  }
}

export default PageHeader;
