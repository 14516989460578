import styled from "styled-components";
import color from "tinycolor2";

import colors from "../../../styles/colors";

const Link = styled.a`
  color: ${colors.primary};
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;

  :hover {
    color: ${color(colors.primary).brighten().toString()}
  }
`;

export default Link;
