import React from "react";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";

const Background = styled.label`
  overflow: hidden;
  position: relative;
  display: inline-block;

  input[type=file] {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
  }
`;

interface IFileUpload extends React.HTMLAttributes<HTMLButtonElement> {
  message?: string;
  onUpload: (file: any) => void;
  acceptedType: string;
}

const FileUpload = (props: IFileUpload) => {
  const { message, onUpload, acceptedType, style } = props;
  return (
    <Background style={style}>
      <Button icon labelPosition="left">
        <Icon name="cloud upload" />{message}
      </Button>
      <input
        type="file"
        accept={acceptedType}
        onChange={(e: any) => onUpload(e.target.files[0])}
      />
    </Background>);
};

FileUpload.defaultProps = {
  onUpload: () => { },
  acceptedType: "image/*",
};

export default FileUpload;
