import { asField } from "informed";
import React from "react";
import { Dropdown } from "semantic-ui-react";

import Label from "./Label";
import Notification from "./Notification";
import Popup from "./Popup";

const DropdownInput = asField(({ fieldState, fieldApi, withErrorMessage, size, ...props }: any) => {
  const { value, error } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, forwardedRef, defaultValue, debug, ...rest } = props;
  return (
    <>
      <Dropdown
        {...rest}
        ref={forwardedRef}
        size={size}
        value={!value && value !== 0 ? defaultValue : value}
        error={error}
        onChange={(e, data) => {
          setValue(data.value);
          if (onChange) onChange(data.value);
        }}
        onBlur={(e) => {
          setTouched();
          if (onBlur) onBlur(e);
        }}
      />
      {withErrorMessage && <Notification>{error}</Notification>}
    </>);
});

const CategoryField = (props: any) => {
  const {
    id,
    field,
    value,
    values = [],
    defaultValue,
    parentNode,
    label,
    children,
    description,
    multiple,
    ...rest } = props;

  // if the dropdown is of type selection, convert the default value to an array if it is not already
  const selectionDefault = multiple && !Array.isArray(defaultValue) ? [defaultValue] : defaultValue;

  return (
    <Popup
      description={description}
      trigger={
        <div style={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
          {(label) && <Label htmlFor={id}>{label}</Label>}
          <DropdownInput
            size="mini"
            selection
            multiple={multiple}
            field={field}
            id={id}
            defaultValue={selectionDefault}
            options={values.map(({ label, id, icon }: any) => ({ text: label, value: id, icon }))}
            {...rest}
          />
        </div>}
    />);
};

export default CategoryField;
