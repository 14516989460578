import React, { Component } from "react";
import { Container, Icon } from "semantic-ui-react";
import styled from "styled-components";

import colors from "../../styles/colors";
import index from "../../styles/index";
import media from "../../styles/media";

const BaseDiv = (props: any) => {
  const { inverted, primary, active, children, ...rest } = props;
  return <div {...rest}>{children}</div>;
};

const HeaderTitle = styled(BaseDiv)`
  font-family: Quicksand, Lato, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  font-size: 24px;
  color: ${(props: any) => (props.inverted ? "#FFFFFF" : colors.font)};
  align-self: center;
  cursor: pointer;
`;

const HeaderBackground = styled(BaseDiv)`
  position: relative;
  justify-content: space-between;
  display: flex;
  height: 50px;
  min-height: 50px;
  padding: 0 18px;
  background: transparent;
  z-index: ${index.header};

  && .ui.menu {
    border: none;
    margin: 0 0 -1px 0;
  }

  && .ui.search {
    align-self: center;
  }

  .ui.secondary.pointing.menu .item {
    border-bottom-width: 1px;
  }
`;

const HeaderMenu = styled(BaseDiv)`
  display: flex;
  > * {
    margin-left: 2rem !important;
    align-self: center;
  }

  .ui.button {
    margin: 0;
  }

  ${media.mobile`
    display: none;
  `}
`;

const MobileMenuBackground: any = styled(BaseDiv)`
  display: none;

  ${media.mobile`
    display: flex;
  `}

  .icon {
    color: ${(props: any) => props.inverted ? "white" : props.active ? colors.font : colors.fontBright};
  }
`;

function MobileMenu(props: any) {
  const { inverted } = props;
  return (
    <MobileMenuBackground inverted={inverted}>
      <Icon size="large" name="bars" style={{ cursor: "pointer" }} />
    </MobileMenuBackground>
  );
}

const Separator = styled.div`
  height: 2rem;
  width: 2px;
  background: ${colors.primary};
`;

const HeaderContainer = styled(Container)`
  &&&& {
    display: flex;
    justify-content: space-between;

    > * {
      align-self: center;
    }

    ${media.mobile`
      flex: 1;
    `}
  }
`;

const HeaderItem: any = styled(BaseDiv)`
  line-height: 50px;
  min-width: 4rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.05rem;
  white-space: nowrap;
  color: ${(props: any) => props.inverted ? "white" : props.active ? colors.font : colors.font1};
  opacity: ${(props: any) => !props.inverted ? 1 : props.active ? 1 : 0.8};
  cursor: pointer;
  :hover {
    color: ${(props: any) => props.inverted ? "white" : colors.font};
    opacity: 1;
  }
`;

class Header extends Component<any, any> {

  static Title = HeaderTitle;
  static Menu = HeaderMenu;
  static Item = HeaderItem;
  static Separator = Separator;
  static MobileMenu = MobileMenu;

  render() {
    const { children, primary, ...rest } = this.props;
    return (
      <HeaderBackground {...rest}>
        <HeaderContainer>
          {children}
        </HeaderContainer>
      </HeaderBackground>);
  }
}

export default Header;
