import { asField } from "informed";
import React from "react";

import Checkbox from "../../../elements/Checkbox/Checkbox";
import Label from "./Label";
import Notification from "./Notification";
import Popup from "./Popup";

const FormCheckbox = asField(({ fieldState, fieldApi, withErrorMessage, type, ...props }: any) => {
  const { value = props.defaultChecked, error } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, forwardedRef, defaultChecked, ...rest } = props;

  const CB = React.forwardRef((props: any, ref: any) => (<Checkbox {...props} ref={ref} />));

  return (
    <React.Fragment>
      <CB
        {...rest}
        defaultChecked={defaultChecked}
        checked={value}
        error={error}
        onChange={(e: any) => {
          setValue(e.target.checked);
          if (onChange) onChange(e);
        }}
        onBlur={(e: any) => {
          setTouched();
          if (onBlur) onBlur(e);
        }}
      />
      {withErrorMessage && <Notification>{error}</Notification>}
    </React.Fragment>);
});

const BooleanField = (props: any) => {
  const { id, value, parentNode, defaultValue, label, description, ...rest } = props;
  return (
    <Popup
      description={description}
      trigger={
        <div>
          {(parentNode === "row") && <Label htmlFor={id}>{label}</Label>}
          <FormCheckbox
            label={parentNode !== "row" && label}
            field={id}
            id={id}
            defaultChecked={defaultValue}
            {...rest}
          />
        </div>}
    />);
};

export default BooleanField;
