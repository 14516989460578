import * as React from "react";
import styled from "styled-components";

interface IRemovableNode {
  removeNode: () => void;
  nodeProps: any;
  Node: any;
}

/* can be removed from a form */

class RemovableNode extends React.Component<IRemovableNode, {}> {
  public render() {
    const { children, removeNode, Node, nodeProps } = this.props;
    return (
      <React.Fragment>
        <Node {...nodeProps}>
          <button onClick={removeNode}>remove</button>
          {children}
        </Node>
      </React.Fragment>
    );
  }
}

export default styled(RemovableNode)``;
