import styled from "styled-components";

import { fadeIn } from "../../styles/animations";

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  justify-content: ${(props: any) => props.center && "center"};
  align-items: ${(props: any) => props.center && "center"};
  min-height: ${(props: any) => props.tall && "100%"};
  /* animation: ${() => fadeIn(0.75)} .6s ease-out forwards; */
`;

const Body = styled.div`
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Page: any = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
`;

Page.Content = Content;
Page.Body = Body;

export default Page;
