import { asField } from "informed";
import React, { Fragment } from "react";
import styled from "styled-components";

import colors from "../../../styles/colors";
import Label from "./Label";
import Popup from "./Popup";
import Notification from "./Notification";

const StyledInput = styled("input")`
  padding: 4px;
  border: 1px solid #e2e2e2;
  color: ${colors.font};
`;

const TimeInput = asField(({ fieldState, fieldApi, withErrorMessage, size, ...props }: any) => {
  const { value, error } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, forwardedRef, defaultValue, ...rest } = props;

  return (
    <Fragment>
      <StyledInput
        {...rest}
        type="time"
        ref={forwardedRef}
        value={!value && value !== 0 ? defaultValue : value}
        error={error}
        onChange={(e) => {
          const date = e.target.value;
          setValue(date);
          if (onChange) onChange(date);
        }}
        onBlur={(e: any) => {
          setTouched();
          if (onBlur) onBlur(e);
        }}
      />
      {withErrorMessage && <Notification>{error}</Notification>}
    </Fragment>);
});

const TimeField = ({ id, label, parentNode, description, ...rest }: any) => {
  return (
    <Popup
      description={description}
      trigger={
        <div style={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
          {(label || parentNode === "row") && <Label htmlFor={id}>{label}</Label>}
          <TimeInput size="mini" field={id} id={id} {...rest} />
        </div>}
    />);
};

export default TimeField;
