import { flatten, unflatten } from "flat";
import * as Informed from "informed";
import set from "lodash.set";
import get from "lodash.get";
import merge from "lodash.merge";
import unset from "lodash.unset";
import * as React from "react";

import DropField from "../Nodes/DropField";
import DynamicNode from "../Nodes/DynamicNode";
import FieldGroup from "../Nodes/FieldGroup";
import FieldRow from "../Nodes/FieldRow";
import FieldTab from "../Nodes/FieldTab";
import RemovableNode from "../Nodes/RemovableNode";
import {
  collection,
  defaultFieldValues,
  field,
  ICategoryField,
  IDefaultFields,
  IFormCollection,
  IFormField,
  IFormNode,
  isCategory,
  isCollection,
  isDynamic,
  isField,
} from "../types";
import DynamicNodeHandler from "./DynamicNodeHandler";

interface IForm {
  id: string;
  NumberField?: any;
  TextField?: any;
  ColorField?: any;
  CategoryField?: any;
  CategoryItem?: any;
  BooleanField?: any;
  TimeField?: any;
  DateField?: any;
  DropField?: any;
  FieldGroup?: any;
  FieldRow?: any;
  FieldTab?: any;
  RemovableNode?: any;
  DynamicNode?: any;
  formDefinition: IFormNode;
  initialValues?: object;
  getApi: (api: any) => void;
  onSubmit?: (form: object) => void;
  onChange?: (form: object) => void;
  onValueChange?: (form: object) => void;
}

class Formalizer extends React.Component<IForm, any> {

  public static defaultProps: Partial<IForm> = {
    BooleanField: Informed.Checkbox,
    CategoryField: Informed.Select,
    CategoryItem: ({ value }: any) => <option value={value.id}>{value.label}</option>,
    ColorField: Informed.Text,
    DateField: Informed.Text,
    DropField,
    DynamicNode,
    FieldGroup,
    FieldRow,
    FieldTab,
    NumberField: Informed.Text,
    RemovableNode,
    TextField: Informed.Text,
    TimeField: Informed.Text,
    initialValues: {},
    getApi: () => { },
  };

  private formApi: any;
  private initialValues: object;

  constructor(props: IForm) {
    super(props);
    this.initialValues = this.getInitialValues(props.formDefinition, props.initialValues);
  }

  private setFormApi = (formApi: any) => {
    const { getApi } = this.props;
    this.formApi = formApi;
    getApi(formApi);
  }

  // add default values in the form state
  private addNode(path: string, elements: IFormNode[]): void {
    const defaults = this.getDefaults({ elements, type: collection.group });
    const currentValues = this.formApi.getState().values;
    const defaultsWithPath = set({}, path, defaults);
    const newFormState = merge({}, currentValues, defaultsWithPath);
    this.formApi.setValues(newFormState);
  }

  // remove a node from the form state given a path
  private removeNode(path: string): void {
    const currentValues = this.formApi.getState().values;
    unset(currentValues, path);
    this.formApi.setValues(currentValues);
  }

  private makeField(fieldType: field) {
    const { TextField, NumberField, CategoryField, ColorField } = this.props;
    const { BooleanField, TimeField, DateField, DropField } = this.props;
    switch (fieldType) {
      case field.text:
        return TextField;
      case field.number:
        return NumberField;
      case field.category:
        return CategoryField;
      case field.color:
        return ColorField;
      case field.boolean:
        return BooleanField;
      case field.dropzone:
        return DropField;
      case field.time:
        return TimeField;
      case field.date:
        return DateField;
      default:
        return () => null;
    }
  }

  private makeNode(nodeType: collection) {
    const { FieldGroup, FieldRow, FieldTab } = this.props;
    switch (nodeType) {
      case collection.group:
        return FieldGroup;
      case collection.row:
        return FieldRow;
      case collection.tab:
        return FieldTab;
      default:
        return () => null;
    }
  }

  // render the jsx form from a definition
  private renderForm(formDefinition: IFormNode, idx = 0) {
    if (!formDefinition) { return null; }

    const { CategoryItem, RemovableNode, DynamicNode } = this.props;
    const { type } = formDefinition;

    if (isDynamic(formDefinition)) {
      const { elements = [], id = "", dynamic, ...nodeProps } = formDefinition as IFormCollection;
      const Node = this.makeNode(type as collection);

      // set the initial values in the dynamic form
      const defaultNodes = Object.keys(get(this.initialValues, id, {}));

      return (
        <DynamicNodeHandler
          Node={Node}
          RemovableNode={RemovableNode}
          DynamicNode={DynamicNode}
          defaultNodes={defaultNodes}
          onAddNode={(nodeId) => this.addNode(`${id}.${nodeId}`, elements)}
          onRemoveNode={(nodeId) => this.removeNode(`${id}.${nodeId}`)}
          nodeProps={nodeProps}
          parentNode={type}
          key={idx}
          id={id}
        >
          {
            Array.isArray(elements) && elements.map((element, idx) =>
              this.renderForm({ parentNode: type, ...element } as IFormNode, idx))
          }
        </DynamicNodeHandler>);
    }

    if (isCollection(formDefinition)) {
      const { elements = [], ...rest } = formDefinition as IFormCollection;
      const Node = this.makeNode(type as collection);
      return (
        <Node key={idx} {...rest}>
          {
            Array.isArray(elements) && elements.map((element, idx) =>
              this.renderForm({ parentNode: type, ...element } as IFormNode, idx))
          }
        </Node>);
    }

    if (isCategory(formDefinition)) {
      const { id = "", values = [], ...rest } = formDefinition as ICategoryField;
      const Field = this.makeField(type as field);
      return (
        <Field key={idx} id={id} field={id} values={values} {...rest}>
          {
            Array.isArray(values)
            && values.map((value, idx) => <CategoryItem key={idx} value={value} />)
          }
        </Field>);
    }

    if (isField(formDefinition)) {
      const { id = "", parentNode, ...rest } = formDefinition as IFormField;
      const Field = this.makeField(type as field);
      return (<Field key={idx} id={id} field={id} {...rest} />);
    }

    return null;
  }

  // extracts the default values from the form definition
  private getDefaults = (formDefinition: IFormNode): object => {
    const defaults = {};

    const rec = (formDefinition: IFormNode) => {
      if (isCollection(formDefinition)) {
        if (!isDynamic(formDefinition)) {
          const { elements = [] } = formDefinition as IFormCollection;
          if (Array.isArray(elements)) elements.map((element) => rec(element));
        } else {
          // extract the default values for dynamic nodes
          // dynamic nodes are a bit more complex:
          // each initial entry needs to be completed with default values
          const { initialValues = {} } = this.props;
          const { id = "" } = formDefinition as IFormNode;
          const nodeDefaults = this.getDefaults({ ...formDefinition, dynamic: false } as IFormNode);
          // for each key of the initial values
          // set the default values of the current dynamic node
          const nodeInitialKeys = Object.keys((initialValues as any)[id] || {});
          nodeInitialKeys.forEach((key) => set(defaults, `${id}.${key}`, nodeDefaults));
        }
      } else if (isField(formDefinition)) {
        const { type, defaultValue, id } = formDefinition as IFormField;
        set(defaults, id, defaultValue || defaultFieldValues[(type as keyof IDefaultFields)]);
      }
    };
    rec(formDefinition);
    return defaults;
  }

  // extends initial values with the defaultvalues from the form definition
  private getInitialValues(formDefinition: IFormNode, initialValues?: object): object {
    // flatten required due to weird lodash merge behavior
    const flatMerge = merge(
      flatten(this.getDefaults(formDefinition || {})),
      flatten(initialValues || {}));
    return unflatten(flatMerge);
  }

  render() {
    const { onChange, onSubmit, children, formDefinition } = this.props;
    const { onValueChange } = this.props;
    return (
      <Informed.Form
        initialValues={this.initialValues}
        getApi={this.setFormApi}
        onChange={onChange}
        onValueChange={onValueChange}
        onSubmit={onSubmit}
      >
        {
          () =>
            <React.Fragment>
              {this.renderForm(formDefinition)}
              {children}
            </React.Fragment>
        }
      </Informed.Form >);
  }
}

export default Formalizer;
