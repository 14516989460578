import React, { Component } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";

import Tile from "../../elements/Tile/Tile";
import theme from "../../styles/theme";

// see semantic/theme/default/site.variables
const breakpoints = {
  lg: 1200,
  md: 992,
  sm: 768,
};

const Layout = WidthProvider(Responsive);
const ResponsiveGridLayout = styled(Layout)`
  position: relative;
  transition: height 200ms ease;

  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    cursor: grab;
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.react-grid-placeholder {
    background: ${theme.backgroundSecondary};
    opacity: 0.8;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 4px;
    bottom: 4px;
    width: 10px;
    height: 10px;
    border-right: 3px solid ${theme.borderHighlight};
    border-bottom: 3px solid ${theme.borderHighlight};
  }`;

class TileGrid extends Component<any, any> {

  static defaultProps = {
    rowHeight: 100,
    editable: false,
    layouts: {},
    breakpoints,
    cols: { lg: 8, md: 4, sm: 2 },
  };

  static Tile = Tile;

  render() {
    const { layouts, editable, breakpoints, rowHeight, cols, ...rest } = this.props;

    return (
      <ResponsiveGridLayout
        {...rest}
        margin={[4, 4]}
        isDraggable={!!editable}
        isResizable={!!editable}
        rowHeight={rowHeight}
        measureBeforeMount
        layouts={layouts}
        breakpoints={breakpoints}
        cols={cols}
      />
    );
  }
}

export default TileGrid;
