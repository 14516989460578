import noop from "lodash.noop";
import * as React from "react";
import { Icon, Menu, Popup } from "semantic-ui-react";
import styled from "styled-components";

import colors from "../../styles/colors";
import SidebarContext from "./Context";

const MenuItem = styled(Menu.Item)`
  &&&&&& {
    white-space: nowrap;
    justify-content: left;
    line-height: 21px;
    height: 41px;
    width: 21px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    border-radius: 0;
    border-left: 4px solid ${(props) => props.active ? colors.primary : "transparent"};
    background: transparent;

    :hover {
      background: transparent;
    }

    span {
      margin-left: 13px;
      align-self: center;
    }
  }
`;

const SidebarIcon = (props: any) => {
  const { onClick, active, size = "large", text, className, name, style = {} } = props;
  return (
    <MenuItem onClick={onClick} active={active} className={className}>
      <Icon
        size={size}
        name={name}
        style={{ margin: 0, display: "flex", width: "21px", alignSelf: "center", ...style }}
      />
      <span>{text}</span>
    </MenuItem>);
};

const Item = (props: any) => {
  const { open, popupMessage, text, onClick = noop, canOpen = false, id, nav, icon, closedIcon, ...rest } = props;

  return (
    <SidebarContext.Consumer>
      {
        (sidebar = {} as any) => {
          const active = sidebar.menuId === id;

          // you can display a different icon when the sidebar is closed
          const iconName = !sidebar.open && closedIcon ? closedIcon : icon;

          const handleClick = () => {
            if (!sidebar.open) sidebar.toggleOpen();

            if (!active && canOpen) sidebar.openMenu(id);

            if (active) {
              sidebar.closeMenu();
              sidebar.toggleOpen();
            }

            onClick();
          };

          // wrapping the Icon seems necesary: popup does not recognize the target otherwise
          const IconRender = (
            <div>
              <SidebarIcon
                {...rest}
                style={{ fontSize: "1.2em" }}
                name={iconName}
                // if the sidebar is closed don't display the tooltip
                text={sidebar.open && text}
                active={nav && active}
                onClick={handleClick}
              />
            </div>);

          if (!sidebar.open && popupMessage) {
            return (<Popup
              on="hover"
              content={popupMessage}
              position="right center"
              basic
              trigger={IconRender}
            />);
          }

          return IconRender;
        }}
    </SidebarContext.Consumer>);
};

export default Item;
