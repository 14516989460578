import escapeRegExp from "lodash.escaperegexp";
import React, { Component } from "react";
import styled from "styled-components";

import { Header as Headline, Image, Label, List, Popup, Search } from "semantic-ui-react";

const CardItem = (props: any) => {
  const { title, description, thumbnailURL, permission, placeholder, nature, ...rest } = props;
  return (
    <div {...rest}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {
          thumbnailURL &&
          <Image
            size="mini"
            src={thumbnailURL}
            style={{ margin: "0 4px 0 0", width: nature === "button" ? "28px" : "44px" }}
          />
        }
        {
          (title || description) &&
          <div
            style={{
              fontSize: ".75rem",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: nature === "button" ? "180px" : "230px",
              textOverflow: "ellipsis",
              flex: 1,
              marginRight: "4px",
            }}
          >
            <Headline as="h5" style={{ fontSize: nature === "button" ? ".8rem" : "1rem" }}>
              {title}
            </Headline>
            {description}
          </div>
        }
      </div>
      <div>
        {
          !title && !description && placeholder
        }
      </div>
      <div style={{ display: "flex" }}>
        {
          permission
          && <Label color="blue" style={{ alignSelf: "center" }}>{permission}</Label>
        }
      </div>
    </div>);
};

const StyledCard = styled(CardItem)`
  ${({ title }) => !title && `
    color: rgba(0,0,0,.87);
    font-weight: 700;
    background-color: #e0e1e2;
    line-height: 28px;

    :hover {
      background-color: #cacbcd;
    }
  `}

  :hover {
    ${({ nature, title }) => nature === "button" && title && "border-color: rgba(34,36,38,.35)"};
    ${({ nature }) => nature !== "button" && "background-color: #e0e1e2"};
  }
  width: 100%;
  display: flex;
  max-width: ${({ nature }) => (nature === "button" ? "280px" : "380px")};
  overflow: hidden;
  height: ${({ nature }) => (nature === "button" ? " 38px" : "52px")};
  ${({ nature }) => nature === "button" && "border-radius: 4px"};
  ${({ nature }) => nature === "button" && "border: 1px solid #e2e2e2"};
  justify-content: space-between;
  padding: 4px;
  cursor: pointer;
`;

// for some reason the prop "fluid" does not work, override here:
const FullWidthSearch = styled(Search)`
  width: 100%;
  align-self: center;

  div:first-child {
    width: 100%
  }

  &&&& .message.empty {
    display: none;
  }

  &&&&&& .visible {
    visibility: hidden !important;
  }
`;

class CardDropdown extends Component<any, any> {

  state = {
    isSearchLoading: false,
    value: "",
    results: [],
  };

  static Item = StyledCard;

  static defaultProps = {
    resources: [],
    onClick: () => { },
  };

  resetComponent = () => this.setState({ isLoading: false, results: [], value: "" });

  handleSearchChange = (e: any, { value }: any) => {
    if (value.length < 1) return this.resetComponent();

    const { resources } = this.props;
    const re = new RegExp(escapeRegExp(this.state.value), "i");
    const isMatch = (result: any) => re.test(result.title) || re.test(result.description);

    this.setState({
      value,
      results: resources.filter((res: any) => isMatch(res)),
    });
  }

  handleResultSelect = (e: any, { result }: any) => {
    const { onClick } = this.props;
    onClick(result.id);
  }

  render() {
    const { trigger, resources, onClick, ...rest } = this.props;
    const { isSearchLoading, value, results } = this.state;
    const list = !value ? resources : results;

    return (
      <Popup
        {...rest}
        position="bottom center"
        on="click"
        trigger={trigger}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FullWidthSearch
            fluid
            loading={isSearchLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={this.handleSearchChange}
            value={value}
          />
          <List
            style={{ maxHeight: "380px", overflow: "hidden", overflowY: "auto", width: "380px" }}
          >
            {
              list.map((res: any, idx: number) =>
                <StyledCard
                  title={res.title}
                  description={res.description}
                  thumbnailURL={res.thumbnailURL}
                  permission={res.permission}
                  key={idx}
                  onClick={() => onClick(res.id)}
                />)
            }
            {
              !list.length && <StyledCard title="no results" />
            }
          </List>
        </div>
      </Popup>);
  }
}

export default CardDropdown;
