import * as React from "react";

// allows the different parts of the sidebar to share state (e.g. is sidebar open?)

export default React.createContext({
  open: true,
  menuId: null,
  submenuId: null,
  toggleOpen: () => { },
  closeMenu: () => { },
  openMenu: (menuId: string) => { },
  openSubmenu: (submenuId: string) => { },
  openSidebar: () => { },
});
