import * as React from "react";
import styled from "styled-components";

import { IFormNode } from "../types";

const Background = styled.div`
  display: flex;
  >* {
    display: inline-block;
    margin: 0 4px;
  }
`;

class FieldRow extends React.Component<IFormNode & React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { children, label, className } = this.props;
    return (
      <Background>
        <label>{label}</label>
        {children}
      </Background>);
  }
}

export default FieldRow;
