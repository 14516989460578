import React, { Component } from "react";
import { Label, Segment } from "semantic-ui-react";
import styled from "styled-components";
import theme from "../../../styles/theme";

const BaseDiv = ({ open, children, ...rest }: any) => {
  return <div {...rest}>{children}</div>;
};

const Background = styled(Segment)`
  &&& {
    min-width: 60px;
    margin: ${(props) => props.parentNode === "row" && "12px 6px"} !important;
    margin-top: ${(props) => props.parentNode === "row" && "0"};
    padding: ${(props) => props.parentNode === "tab" && "0"} !important;
    background: ${theme.backgroundSecondary};
    border-color: ${theme.borderColor};
  }

  .ui.label {
    cursor: pointer;
  }
`;

const Content = styled(BaseDiv)`
  display: ${(props: any) => (props.open ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  > * {
    margin-bottom: 4px;
    display: flex;
  }
`;

function Group(props: any) {
  const { children, label, basic, parentNode, border, ...rest } = props;
  const [open, setOpen] = React.useState(true);

  return (
    <Background {...rest} basic={basic || parentNode === "tab"}>
      {label && <Label attached="top" onClick={() => setOpen(!open)}>{label}</Label>}
      <Content open={open}>
        {children}
      </Content>
    </Background>);
}

export default Group;
