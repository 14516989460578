import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";

export default styled(Dropdown as any)`
  &&&& {
    min-height: ${(props: any) => props.size === "mini" && 0};
    height: ${(props: any) => props.size === "mini" && "32px"};
    padding: ${(props: any) => props.size === "mini" && ".4rem"};
    font-size: ${(props: any) => props.size === "mini" && ".8rem"};
  }
`;
