import React, { Component } from "react";
import { Tab } from "semantic-ui-react";

export class Tabs extends Component {
  render() {
    const { children = [], ...rest } = this.props;
    const panes = React.Children.map(children, (child: any, key) => {
      const { label, ...rest } = child.props;
      return {
        menuItem: label,
        pane: <Tab.Pane key={key}>{React.createElement(child.type, rest)}</Tab.Pane>,
      };
    });

    return (<Tab style={{ display: "block" }} renderActiveOnly={false} panes={panes} {...rest} />);
  }
}
