import RCNumberInput from "rc-input-number";
import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import { pulse } from "../../styles/animations";
import colors from "../../styles/colors";
import theme from "../../styles/theme";

/* custom number input styled based on antd input number */

const upHandler = (<Icon name="triangle up" style={{ margin: "-4px 0 0 0" }} />);
const downHandler = (<Icon name="triangle down" style={{ margin: "-4px 0 0 0" }} />);

const NumberInput = (props: any) => (
  <RCNumberInput
    upHandler={upHandler}
    downHandler={downHandler}
    {...props}
  />);

export default styled(NumberInput)`
  margin: 0;
  padding: 0;
  line-height: 26px;
  font-size: 12px;
  height: ${(props: any) => (props.size === "mini" ? "30px" : "40px")};
  width: 148px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid ${theme.borderColor};
  border-radius: 4px;
  transition: all 0.1s ease-in;

  :focus-within, :hover, :active {
    border-color: ${({ disabled }: any) => !disabled && colors.primary};
  }

  :focus-within, :active {
    animation: 0.5s ease 0s ${({ disabled }: any) => !disabled && pulse(colors.primary)};
  }

  .rc-input-number-handler-wrap {
    float: right;
    border-left: 1px solid ${theme.borderColor};
    width: 24px;
    height: 100%;
    display: ${({ disabled }: any) => (disabled && "none")};
  }

  .rc-input-number-handler {
    text-align: center;
    line-height: ${(props: any) => (props.size === "mini" ? "15px" : "20px")};
    height: ${(props: any) => (props.size === "mini" ? "14px" : "19px")};
    overflow: hidden;
    display: block;
    touch-action: none;
    cursor: ${({ disabled }: any) => (!disabled ? "pointer" : "not-allowed")};
  }

  .rc-input-number-handler.rc-input-number-handler-up,
  .rc-input-number-handler.rc-input-number-handler-down {

    color: ${theme.textSecondary};

    :hover {
      background: ${colors.primary};
      color: ${theme.textPrimary};
    }
  }

  .rc-input-number-handler.rc-input-number-handler-down-disabled,
  .rc-input-number-handler.rc-input-number-handler-up-disabled {
    background-color: ${theme.backgroundDisabled};
    color: ${theme.textSecondary};

    :hover {
      color: ${theme.textSecondary};
      background-color: ${theme.backgroundDisabled};
      cursor: not-allowed;
    }
  }

  .rc-input-number-input-wrap {
    overflow: hidden;
    height: 100%;
  }

  .rc-input-number-input {
    width: 100%;
    text-align: center;
    outline: 0;
    line-height: 35px;
    height: 100%;
    transition: all 0.3s ease;
    border: 0;
    padding: 0;

    cursor: ${({ disabled }: any) => (disabled ? "not-allowed" : "pointer")};
    color: ${({ disabled }: any) => (disabled ? theme.textSecondary : theme.textPrimary)};
    background: ${({ disabled }: any) => (disabled ? theme.backgroundDisabled : theme.background)};
  }
`;
