import noop from "lodash.noop";
import * as React from "react";
import { Icon, Popup } from "semantic-ui-react";
import styled from "styled-components";

import theme from "../../styles/theme";
import SidebarContext from "./Context";

const Content: any = styled.div`
  color: white;
  display: flex;
  min-height: 70px;
  justify-content: center;
  padding-right: ${(props: any) => props.open && "1rem"};
`;

const ItemBackground = styled.div`
  &&&& {
    display: flex;
    min-width: 4rem;
    justify-content: center;
    line-height: 2rem;
    color: white;
    opacity: 0.7;
    cursor: pointer;

    :hover {
      opacity: 1;
      background: rgba(255,255,255,.08);
    }

    i {
      align-self: center;
    }
  }
`;

const Item = (props: any) => {
  const { name, onClick } = props;
  return (
    <Popup
      on="hover"
      content="sign out"
      position="right center"
      basic
      inverted
      trigger={
        <ItemBackground onClick={onClick}>
          <Icon size="large" name={name} />
        </ItemBackground>}
    />
  );
};

const Wrapper: any = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem 1rem;
  position: relative;
`;

const CollapseIcon = styled(Icon)`
  &&&& {
    margin: 0;
  }

  align-self: center;
  cursor: pointer;
  color: ${theme.textSecondary};

  :hover {
    color: ${theme.textPrimary};
  }
`;

class Footer extends React.Component<any, any> {

  static Item = Item;

  render() {
    const { children } = this.props;

    return (
      <SidebarContext.Consumer>
        {
          (sidebar = {} as any) =>
            <Content open={sidebar.open}>
              {
                sidebar.open &&
                <Wrapper>
                  {children}
                </Wrapper>
              }
              <CollapseIcon
                size="large"
                onClick={sidebar.toggleOpen}
                name={sidebar.open ? "arrow circle left" : "arrow circle right"}
              />
            </Content>
        }
      </SidebarContext.Consumer>);
  }
}

export default Footer;
