import "rc-slider/assets/index.css";

import RCSlider from "rc-slider";
import React from "react";
import styled from "styled-components";

import colors from "../../styles/colors";

const SliderWithTooltip = (RCSlider as any).createSliderWithTooltip(RCSlider);
const RangeSliderWithTooltip = (RCSlider as any).createSliderWithTooltip((RCSlider as any).Range);

const Slider = ({ range, ...rest }: any) => {
  if (range) return <RangeSliderWithTooltip {...rest} />;
  return (<SliderWithTooltip {...rest} />);
};

export default styled(Slider)`

  .rc-slider-mark-text {
    width: auto !important;
    margin-top: -5px !important;
    margin-left: -5px !important;
  }

  .rc-slider-handle {
    border-color: ${colors.primary};

    :active, :hover, :focus {
      border-color: ${colors.primary};
      box-shadow: 0 0 5px ${colors.primary};
    }
  }

  .rc-slider-dot-active {
    border-color: ${colors.primary};
  }

  .rc-slider-track {
    background: ${colors.primary};
  }
`;
