import React from "react";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  narrow?: boolean;
}

// filter additional props
const ExtendedContainer = ({ narrow, children, ...rest }: IContainerProps) =>
  <Container {...rest}>{children}</Container>;

export default styled(ExtendedContainer)`
  && {
    max-width: ${(props) => props.narrow && "480px !important"};
  }
`;
