import { keyframes } from "styled-components";
import color from "tinycolor2";

export const pulse = (pulseColor: string) => keyframes`
  from {
    box-shadow: ${color(pulseColor).brighten().setAlpha(0.8).toString()} 0px 0px 0px;
  }
  to {
    box-shadow: ${color(pulseColor).brighten().setAlpha(0).toString()} 0px 0px 0px 6px;
  }`;

export const fadeIn = (start = 0, end = 1) => keyframes`
  from {
    opacity: ${start};
  }
  to {
    opacity: ${end};
  }`;

export default ({
  pulse,
  fadeIn,
});
